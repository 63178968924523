/** @format */

(function ($) {
    "use strict";

    /*
     * The modal forms on the listing details page are contextually aware of the listing.
     * They use the listing info both for display and to populate fields.
     * We don't care who is authenticated - we just grab the data on the page if it exists.
     */
    var $listingDetails = $("#ListingDetails");

    var streetAddress = $listingDetails.data("streetaddress") || "";
    if (!streetAddress) {
        var $streetAddress = $listingDetails.find(".listing-street-address");
        streetAddress = ($streetAddress.length && $streetaddress.text()) || "";
    }

    var cityStateZip = $listingDetails.data("citystatezip") || "";
    if (!cityStateZip) {
        var $cityStateZip = $listingDetails.find(".listing-city-state-zip");
        cityStateZip = ($cityStateZip.length && $cityStateZip.text()) || "";
    }

    var mlsNumber = $listingDetails.data("mlsno") || "";
    if (mlsNumber) {
        mlsNumber = "- MLS# " + mlsNumber;
    } else {
        var $mlsNumber = $listingDetails.find(".listing-mls-number");
        mlsNumber = ($mlsNumber.length && $mlsNumber.text()) || "";
    }

    var re = ih.utils.strip(
        streetAddress + " " + cityStateZip + " " + mlsNumber
    );
    var re_2 = ih.utils.strip(
        streetAddress + " " + cityStateZip + " " + mlsNumber
    ); // re goes away somewhere, so make a second copy.

    /*
     * Repository of reusable form fields
     */
    var fieldsRepository = {
        reField: function (prefix) {
            return {
                type: "hidden",
                name: "re",
                value: prefix + " - " + re,
            };
        },
        nameField: function () {
            return {
                type: "text",
                label: "Your Name",
                name: "name",
                value: ih.authenticatedUser && ih.authenticatedUser.name,
                required: true,
            };
        },
        phoneField: function () {
            return {
                type: "text",
                label: "Your Phone Number",
                name: "phone",
                value: ih.authenticatedUser && ih.authenticatedUser.phone,
                required: true,
            };
        },
        emailField: function () {
            return {
                type: "text",
                label: "Your Email",
                name: "email",
                value: ih.authenticatedUser && ih.authenticatedUser.email,
                required: true,
            };
        },
        timeField: function () {
            return {
                type: "text",
                label: "When is the best time to contact you?",
                name: "best_time",
            };
        },
        commentField: function (suffix) {
            return {
                type: "textarea",
                label: "Comments/Questions - " + suffix,
                name: "comments",
            };
        },
        captchaField: function () {
            return {
                type: "recaptcha",
                label: "Prove that you are a human",
                name: "captcha",
                required: true,
            };
        },
        agentField: function (forceSite) {
            var $agentDirectoryContactInfo = $("#agent_directory_contact_info");

            return {
                type: "html",
                html:
                    $agentDirectoryContactInfo.length && !forceSite
                        ? $agentDirectoryContactInfo.html()
                        : $("#agent_contact_info").html(),
            };
        },
    };

    /*
     * Configure and return common fields for a contact form
     */
    var getContactFormFields = function (reFieldPrefix, commentFieldSuffix) {
        var fields = [
            fieldsRepository.reField(reFieldPrefix),
            fieldsRepository.agentField(),
            fieldsRepository.nameField(),
            fieldsRepository.phoneField(),
            fieldsRepository.emailField(),
            fieldsRepository.timeField(),
            fieldsRepository.commentField(commentFieldSuffix),
        ];

        // if (ih.data.recaptcha.useForContact) {
        //     fields.push( fieldsRepository.captchaField() );
        // }

        //        fields.push( fieldsRepository.agentField() );

        return fields;
    };

    /*
     * Show the `Request More Information` form
     */
    var showRequestMoreInfoForm = function (e) {
        e.preventDefault();
        e.stopPropagation();

        $(document).trigger("trackingEvent", {
            category: "lead",
            action: "form_view",
            label: "Request More Information",
        });

        var formDefinition = {
            action: $(this).attr("href"),
            fields: getContactFormFields(
                "Listing Inquiry",
                "What would you like to know about this property?"
            ),
            // using a custom success callback to handle our tracking events
            success: function (response, form) {
                ih.success(response.message);
                if (response.tracking_events) {
                    for (var index in response.tracking_events) {
                        $(document).trigger(
                            "trackingEvent",
                            response.tracking_events[index]
                        );
                    }
                }
            },
        };

        var use_captcha = false;
        if (ih.data.recaptcha.useForContact) {
            use_captcha = true;
        }

        ih.modalForm("Re: " + re, null, {
            title: "Request More Information",
            form: formDefinition,
            use_captcha: use_captcha,
        });
    };

    /*
     * Show the `Schedule a Showing` form
     */
    ih.showScheduleAShowingForm = function (e, listing_re, listing_href) {
        console.log("showScheduleAShowingForm start");

        e.preventDefault();
        e.stopPropagation();

        $(document).trigger("trackingEvent", {
            category: "lead",
            action: "form_view",
            label: "Schedule A Showing",
        });

        // are we using reCaptcha?
        var use_captcha = ih.data.recaptcha.useForContact ? true : false;

        // react will load the form definition from the database. Non-react will use the current hard-coded form
        if (ih.data.template.isReact) {
            // retrieve the form definition from the DB
            $.get(ih.url("/AjaxForm/get_form_fields_from_db/"), {
                acnt: ih.acnt,
                custom_form_type: "schedule",
                listing_address: streetAddress + " " + cityStateZip,
                mls_no: mlsNumber,
            }).done(function (formDescriptor) {
                // now define this form with the fields we got
                var formDefinition = {
                    action: listing_href,
                    fields: formDescriptor.fieldList,

                    // using a custom success callback to handle our tracking events
                    success: function (response, form) {
                        ih.success(response.message);
                        if (response.tracking_events) {
                            for (var index in response.tracking_events) {
                                $(document).trigger(
                                    "trackingEvent",
                                    response.tracking_events[index]
                                );
                            }
                        }
                    },
                };

                // pass the form definition off to the modal form object
                ih.modalForm("Re: " + listing_re, null, {
                    title: formDescriptor.formTitle,
                    form: formDefinition,
                    use_captcha: use_captcha,
                });
            });
        } else {
            var formDefinition = {
                action: listing_href,
                fields: getContactFormFields(
                    "Schedule A Showing",
                    "When would you like to see this property?"
                ),
                // using a custom success callback to handle our tracking events
                success: function (response, form) {
                    ih.success(response.message);
                    if (response.tracking_events) {
                        for (var index in response.tracking_events) {
                            $(document).trigger(
                                "trackingEvent",
                                response.tracking_events[index]
                            );
                        }
                    }
                },
            };

            // pass the form definition off to the modal form object
            ih.modalForm("Re: " + listing_re, null, {
                title: "Schedule a Showing",
                form: formDefinition,
                use_captcha: use_captcha,
            });
        }
    };

    /*
     * Show the `Tell a Friend` form
     */
    ih.showTellAFriendForm = function (e) {
        e.preventDefault();
        e.stopPropagation();

        var href = $(this).attr("href") || $(e.target).attr("href");

        var use_re = re;
        if ($(e.target).attr("re")) {
            use_re = $(e.target).attr("re");
        }

        var formDefinition = {
            action: href,
            fields: [
                fieldsRepository.nameField(),
                fieldsRepository.emailField(),
                {
                    type: "text",
                    label: "Recipient's Email",
                    name: "to_email",
                    required: true,
                },
                {
                    type: "text",
                    label: "Subject",
                    value: use_re,
                    name: "subject",
                    required: true,
                },
                {
                    type: "textarea",
                    label: "Add Your Personal Message Here",
                    value: "Here is a property you may be interested in: ",
                    name: "message",
                },
                {
                    type: "html",
                    html:
                        '<span class="instructions">' +
                        "Listing information will automatically be included in&nbsp;the&nbsp;email" +
                        "</span>",
                },
                //                    fieldsRepository.captchaField()
            ],
        };

        var use_captcha = true; // always for tell-a-friend

        ih.modalForm("Re: " + use_re, null, {
            title: "Tell a Friend",
            form: formDefinition,
            use_captcha: use_captcha,
        });
    };

    // Bind events
    $("body")
        .on("click", "#BtnRequestMoreInfo", showRequestMoreInfoForm)
        .on("click", "#BtnTellAFriend", ih.showTellAFriendForm)
        .on("click", "#BtnScheduleAShowing", function (e) {
            var re = re_2; // the global re is gone for some reason, good thing we kept a copy
            var href = $("#BtnScheduleAShowing").attr("href");
            ih.showScheduleAShowingForm(e, re, href);
        });
})(window.jQuery);
